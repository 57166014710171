@import 'styles/utils/mixins';
@import 'styles/utils/sizes';
@import 'styles/utils/colors';

.cards-list {
  @include flex(row, center, initial, wrap);

  gap: 32px;
  margin: 0 auto 48px;
  padding: 0 16px;
  max-width: $default-content-width;

  .card {
    @include flex(column, flex-start, flex-start);

    flex: 0 0 48%;
    padding: 32px;
    background-color: $gray800;
    border-radius: 16px;
    box-sizing: border-box;

    @include tablet {
      flex: 0 0 47%;
    }

    @include below-tablet {
      flex: 0 0 100%;
    }
  }

  .card-icon {
    width: 32px;
    height: 32px;

    path {
      fill: $yellow200;
    }
  }

  .card-title {
    @include fontSize(20px, 150%);

    margin: 32px 0 8px;
    font-weight: 600;
    color: $gray0;
  }

  .card-text {
    p {
      margin: 0;
      color: rgba($gray0, 0.64);
    }
  }

  @include phone {
    gap: 16px;
  }
}
