@import 'styles/utils/mixins';
@import 'styles/utils/sizes';
@import 'styles/utils/colors';

.section {
  padding: 60px 32px;

  .title {
    [data-title] {
      color: $gray0;
      font-weight: 600;
    }
  }

  .container {
    margin: 0 auto;
    padding: 80px 0;
    max-width: $wide-content-width;
    background-color: $gray900;
    border-radius: 24px;

    @include below-tablet {
      padding: 40px 0;
    }
  }

  @include tablet {
    padding: 30px 16px;
  }
}
